<template>
  <div class="advantages">
    <div
      v-for="(item, i) in i18n.list"
      :key="'advantage' + i"
      class="advantages-item"
    >
      <Icon
        class="advantages-icon"
        :type="item.icon"
        to="white"
      />
      <div>
        <h4 class="advantages-title">
          {{ item.title }}
        </h4>
        <p v-html="item.content" />
      </div>
    </div>
  </div>
</template>

<style lang="css">
.advantages {
  display: flex;
  flex-flow: column;
  padding: 1.5rem 0;
  color: #ffffff;
}

.advantages-item {
  display: flex;
  align-items: flex-start;
  margin: 1.2rem 1.5rem;
}

.advantages-title {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 20px;
  line-height: 115%;
}

.advantages-icon {
  min-width: 40px;
  min-height: 40px;
  margin-right: 1.5rem;
}

@media (min-width: 768px) {
  .advantages {
    flex-flow: row;
    padding: 4rem 1.5rem;
    height: 319px;
  }

  .advantages-item {
    display: block;
    margin: 0;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .advantages-icon {
    min-width: 48px;
    min-height: 48px;
    margin-bottom: 1rem;
  }

  .advantages-title {
    margin: 1rem 0;
    font-size: 24px;
    line-height: 115%;
  }
}
</style>

<script>
export default {
  name: "Advantages",
  props: {
    i18n: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
