var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:[
    'header',
    {
      _open: _vm.isOpen,
    },
  ]},[_c('div',{staticClass:"header-mobile"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/logo.svg","alt":"svg"}})]),_c('button',{staticClass:"header-btn-nav",on:{"click":_vm.onMobileOpen}},[_c('Icon',{attrs:{"to":"white","type":_vm.isOpen ? 'close' : 'menu'}})],1)],1),_c('nav',{class:[
      'header-nav',
      {
        _login: _vm.isLogin,
      },
    ]},[(0)?_c('ul',{staticClass:"header-nav-items _login"},_vm._l((_vm.menuLogin),function(item,i){return _c('li',{key:i + 'menuLogin',staticClass:"header-nav-item"},[_c('a',{staticClass:"header-nav-item-link",attrs:{"href":item.href}},[_c('Icon',{staticClass:"__icon",attrs:{"type":item.icon,"to":"white"}}),_c('span',[_vm._v(_vm._s(item.title))])],1)])}),0):_vm._e(),(0)?_c('hr',{staticClass:"__divider _login"}):_vm._e(),_c('ul',{staticClass:"header-nav-items"},_vm._l((_vm.menu),function(item,i){return _c('li',{key:i + 'menu',staticClass:"header-nav-item"},[_c('a',{staticClass:"header-nav-item-link _big",attrs:{"href":item.href}},[_c('Icon',{staticClass:"__icon",attrs:{"type":"arrow-right","to":"white"}}),_c('span',[_vm._v(_vm._s(item.title))])],1)])}),0),_c('hr',{staticClass:"__divider _lk"}),_c('router-link',{staticClass:"header-nav-item-link _big _lk",attrs:{"to":"/login"}},[_c('Icon',{staticClass:"__icon",attrs:{"type":"account"}}),_c('span',[_vm._v("Личный кабинет")]),(0)?_c('span',[_vm._v("Войти")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }