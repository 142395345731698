<template>
  <header
    :class="[
      'header',
      {
        _open: isOpen,
      },
    ]"
  >
    <div class="header-mobile">
      <router-link
        to="/"
        class="logo"
      >
        <img
          src="/logo.svg"
          alt="svg"
        >
      </router-link>
      <button
        class="header-btn-nav"
        @click="onMobileOpen"
      >
        <Icon
          to="white"
          :type="isOpen ? 'close' : 'menu'"
        />
      </button>
    </div>
    <nav
      :class="[
        'header-nav',
        {
          _login: isLogin,
        },
      ]"
    >
      <ul
        v-if="0"
        class="header-nav-items _login"
      >
        <li
          v-for="(item, i) in menuLogin"
          :key="i + 'menuLogin'"
          class="header-nav-item"
        >
          <a
            class="header-nav-item-link"
            :href="item.href"
          >
            <Icon
              class="__icon"
              :type="item.icon"
              to="white"
            />
            <span>{{ item.title }}</span>
          </a>
        </li>
      </ul>
      <hr
        v-if="0"
        class="__divider _login"
      >
      <ul class="header-nav-items">
        <li
          v-for="(item, i) in menu"
          :key="i + 'menu'"
          class="header-nav-item"
        >
          <a
            class="header-nav-item-link _big"
            :href="item.href"
          >
            <Icon
              class="__icon"
              type="arrow-right"
              to="white"
            />
            <span>{{ item.title }}</span>
          </a>
        </li>
      </ul>
      <hr class="__divider _lk">
      <router-link
        class="header-nav-item-link _big _lk"
        to="/login"
      >
        <Icon
          class="__icon"
          type="account"
        />
        <span>Личный кабинет</span>
        <span v-if="0">Войти</span>
      </router-link>
    </nav>
  </header>
</template>

<script lang="js"> 
export default {
  name: "Header",
  model: {
    prop: "value",
    event: "input",
  }
  ,
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    }
    ,
    value: {
      type: Boolean,
    },
  }
  ,
  data() {
    return {
      menulogin:[
        {
          title: "Отчеты", 
          href: "#", 
          icon: "inbox" }, {
          title: "Подписка", 
          href: "#", 
          icon: "done-all" }, {
          title: "Профиль", 
          href: "#", 
          icon: "account-circle" }, {
          title: "Документы", 
          href: "#", 
          icon: "work-outline" }, {
          title: "Обратная связь", 
          href: "#", 
          icon: "comment" }, {
          title: "Выход", 
          href: "#", 
          icon: "exit-to-app" } 
      ],
      menu:[
        {
          title: "Как получить", 
          href: "#howget" }, {
          title: "Условия", 
          href: "#conditions" },
        {title: "Вопрос/ответ", 
          href: "#questions" } 
      ],
    }
  }
  ,
  computed: {
    isOpen: {
      get() {
        return this.value
      }
      ,
      set(value) {
        this.$emit("input", value)
      },
    },
  }
  ,
  methods: {
    onMobileOpen() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="css" src="./header.css" />
